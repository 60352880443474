<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar title="我的证书" left-arrow @click-left="$router.back()">
          <template #right>
            <div @click="toHome()">
              <van-icon name="wap-home-o" size="18" />
              <div style="float: right; margin-left: 5px; font-size: 15px">
                首页
              </div>
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center">
          {{ courses.title }}
        </h2>
        <h2 style="text-align: center">
          <h4>{{ courses.remark ? courses.remark : courses.creationTime }}</h4>
        </h2>
      </div>

      <div style="text-align: center" class="acontent">
        <van-image :src="courses.imageUrl" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getMyCertificateDetail } from "@/api/userCertificate";

export default {
  name: "myCertificateDetail",
  data() {
    return {
      loading: true,
      title: "",
      courses: {},
    };
  },
  components: {},

  created() {
    this.onLoad();
  },
  updated() {
    this.addpreview();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/user/mycertificatedetail") {
        if (to.query != from.query) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getMyCertificateDetail(para); //({ ...this.page })
      this.courses = aresult.data.data;
      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.courses.id);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>
  
  
  <style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  padding: 5px 5px 5px 5px;
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>